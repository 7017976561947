<template>
  <div v-if="showSnackbar" id="snackbar" class="show" style="font-size: 14px">
    จะลบทั้งหมดภายใน...
    <b class="ms-3" :style="textStyle">{{ timeout }}</b>
    <button
      type="button"
      class="btn btn-link btn-color-danger ms-4"
      style="font-size: 14px; font-weight: bold"
      @click="closeSnackbar"
    >
      เลิกทำ
    </button>
  </div>
</template>

<script>
export default {
  props: {
    showSnackbar: Boolean,
  },
  data: () => ({
    timeout: 7,
    textStyle: "font-size: 18px;color: MediumSpringGreen;",
  }),
  watch: {
    showSnackbar(val) {
      if (val) {
        this.handleTimeout();
        setTimeout(() => {
          this.closeSnackbar();
        }, 7300);
      }
    },
    timeout(val) {
      if (val === 6) {
        this.textStyle = "font-size: 18px;color: LightGreen;";
      }
      if (val === 5) {
        this.textStyle = "font-size: 18px;color: YellowGreen;";
      }
      if (val === 4) {
        this.textStyle = "font-size: 18px;color: Yellow;";
      }
      if (val === 3) {
        this.textStyle = "font-size: 18px;color: Gold;";
      }
      if (val === 2) {
        this.textStyle = "font-size: 18px;color: Orange;";
      }
      if (val === 1) {
        this.textStyle = "font-size: 18px;color: OrangeRed;";
      }
      if (val === 0) {
        this.textStyle = "font-size: 18px;color: DarkRed;";
      }
    },
  },
  methods: {
    closeSnackbar() {
      this.timeout = 100;
      this.$emit("closeSnackbar");
    },
    handleTimeout() {
      if (this.timeout > 0 && this.timeout < 8) {
        setTimeout(() => {
          this.timeout -= 1;
          this.handleTimeout();
        }, 1000);
      } else if (this.timeout === 0) {
        this.$emit("snackbarConfirmDelete");
      }
    },
  },
};
</script>

<style scoped>
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding: 8px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 7s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>
